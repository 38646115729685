<template>
    <div>
        <div class="main-content">
            <div class="content">
                <div class="tab">
                    <el-button type="primary" @click="addBtn">添加分类</el-button>
                </div>
                <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px" height="1%">
                    <el-table-column prop="sort" label="分类排序" align="center" width="200" sortable></el-table-column>
                    <el-table-column prop="category_name" label="分类名称" align="center"></el-table-column>
                    <el-table-column label="操作" align="center" width="200">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" @click="editBtn(scope.row)">编辑</el-link>
                            <el-link type="danger" :underline="false" @click="delBtn(scope.row)">删除
                            </el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center"
                               :current-page="listPages.currentPageNum"
                               :page-size="listPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="listPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
                <el-dialog :title="this.addOrEdit === 'edit'?'编辑分类':'添加分类'" :visible.sync="dialogVisible" width="500px"
                           custom-class="custom-dialog"
                           :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
                    <el-form :model="addForm" :rules="rules" ref="addForm" label-width="110px" class="demo-ruleForm">
                        <el-form-item label="分类名称" prop="category_name">
                            <el-input v-model="addForm.category_name"></el-input>
                        </el-form-item>
                        <el-form-item label="分类排序">
                            <el-input-number class="number-input" v-model="addForm.sort" :precision="0"
                                             :controls="false"
                                             :min="1" :max="255"></el-input-number>
                            <span class="prompt-text">数值越小越靠前</span>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" style="text-align: center">
                        <el-button @click="dialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="submitForm('addForm')" style="margin-left: 30px">保存
                        </el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
    import {categoryList, addCategory, delCategory} from '@/config/apis'

    export default {
        name: "GoodClass",
        data() {
            return {
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                // 弹窗
                dialogVisible: false,
                addForm: {
                    category_id: null,
                    category_name: '',
                    sort: undefined,
                },
                rules: {
                    category_name: {required: true, message: '请输入学校名称', trigger: 'blur'},
                },
                addOrEdit: null,
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    paging: '1',
                    pageSize: this.listPages.eachPageNum,
                    page: this.listPages.currentPageNum,
                }
                categoryList(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            addBtn() {
                this.addOrEdit = 'add'
                this.dialogVisible = true
            },
            editBtn(row) {
                this.addOrEdit = 'edit'
                this.addForm = {
                    category_id: row.category_id,
                    category_name: row.category_name,
                    sort: row.sort
                }
                this.dialogVisible = true
            },
            delBtn(row) {
                this.$confirm('删除后将无法恢复，确定要删除？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let param = {
                        category_id: row.category_id
                    }
                    delCategory(param).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                        this.dialogVisible = false
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },

            // 打开弹窗
            openedDialog() {

            },
            // 关闭弹窗
            closeDialog() {
                this.resetForm()
                this.addOrEdit = null
            },
            // 弹窗内保存
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('category_name', this.addForm.category_name)
                        if (this.addForm.sort) {
                            formData.append('sort', this.addForm.sort)
                        }
                        if (this.addForm.category_id) {
                            formData.append('category_id', this.addForm.category_id)
                        }
                        addCategory(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.getList()
                            this.dialogVisible = false
                            this.resetForm()
                        }).catch((err) => {
                            console.error(err)
                        })
                    } else {
                        return false;
                    }
                });
            },
            // 重置表单
            resetForm() {
                this.$refs.addForm.resetFields();
                this.addForm = {
                    category_id: null,
                    category_name: '',
                    sort: undefined
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .prompt-text {
        margin-left: 10px;
    }

    .number-input {
        ::v-deep .el-input__inner {
            text-align: left;
        }
    }
</style>